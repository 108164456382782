<template>
  <div class="wrapper">
    <div class="page-header header-filter">
      <div
        class="page-header-image"
        style="background-image: url('https://pharmaguide.ca/wp-content/uploads/2021/02/womans-hand-taking-pills-from-theeveryday-blister-8MVG28T-scaled.jpg');"
      ></div>
      <div class="container">
        <div class="row">
          <!-- <div class="col-lg-10 mx-auto text-center">
            <h2 class="display-2 text-white">REFRIGERATOR PROCEDURE</h2>
          </div> -->
        </div>
        <div class="row">
          <div class="floating-box bg-default">
            <div class="box text-center">
              <div
                class="icon icon-shape bg-primary icon-xl rounded-circle text-white"
              >
                <i class="ni ni-bullet-list-67"></i>
              </div>
            </div>
            <blockquote class="blockquote text-center mt-4">
              <!-- <p class="mb-0 text-white">Refrigerator Procedure</p> -->
              <h1 class="display-3 text-white">
                Compliance Packaging procedure
              </h1>
              <!-- <footer class="blockquote-footer text-white">
                Someone famous in
                <cite title="Source Title">Source Title</cite>
              </footer> -->
            </blockquote>
            <h2 class="lead text-white p-4">
              Please find below the Procedure for Compliance Packaging
              prescription fulfillment. Please Conduct independent double checks
              whenever possible in the pharmacy workflow
            </h2>
          </div>
        </div>
      </div>
    </div>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h6 class="category">Feb 4, 2021</h6>
            <h3 class="title">Compliance Packaging procedure</h3>
            <p></p>
            <ol class="c1 lst-kix_list_1-0 start" start="1">
              <li class="c0 c4 li-bullet-0">
                <span class="c2"
                  >Pharmacist verifies medications entered in patient profile
                  against original prescriptions and computer generated
                  compliance package grid and prints it for assistant.</span
                >
              </li>
            </ol>
            <ul class="c1 lst-kix_list_1-1 start" start="1">
              <li class="c3 c7 li-bullet-0">
                <span class="c3"
                  >Note: For first fills, another staff member will verify the
                  grid as a secondary check against the original
                  prescriptions.</span
                >
              </li>
              <li class="c3 c7 li-bullet-0">
                <span class="c3"
                  >Pharmacist highlights all medications with special dosing
                  schedules.</span
                >
              </li>
            </ul>
            <ol class="c1 lst-kix_list_1-0" start="2">
              <li class="c0 c4 li-bullet-0">
                <span class="c2"
                  >Assistant collects medications and arranges them in order of
                  the grid.</span
                >
              </li>
              <li class="c0 c4 li-bullet-0">
                <span class="c2"
                  >Assistant verifies DIN by initialling on the grid beside each
                  DIN</span
                >
              </li>
              <li class="c0 c4 li-bullet-0">
                <span class="c2"
                  >Assistant records Expiry date of each medication on the
                  grid.</span
                >
              </li>
              <li class="c0 c4 li-bullet-0">
                <span class="c2"
                  >Assistant pre-counts each medication and places it in a vial
                  on top of the original stock bottle</span
                >
              </li>
            </ol>
            <ul class="c1 lst-kix_list_1-1 start" start="1">
              <li class="c0 c7 li-bullet-0">
                <span class="c10 c14"
                  >Medications in compliance aids must be packaged as soon as
                  possible after being removed from the stock bottle to minimize
                  atmospheric exposure and protect the integrity of the
                  medication</span
                >
              </li>
            </ul>
            <ol class="c1 lst-kix_list_1-0" start="6">
              <li class="c0 c4 li-bullet-0">
                <span class="c2"
                  >Pharmacist verifies stock bottles by visually inspecting drug
                  shape and colour, DIN, drug name, drug strength and expiry
                  date and ok&rsquo;s filling of package</span
                >
              </li>
              <li class="c0 c4 li-bullet-0">
                <span class="c2">Assistant puts on gloves</span>
              </li>
              <li class="c0 c4 li-bullet-0">
                <span class="c2"
                  >Assistant fills compliance package in a quiet and
                  non-distracting area of the pharmacy following the grid and
                  double checking location of each pill. Initial beside the
                  location of the medication in the table on the grid.</span
                >
              </li>
            </ol>
            <ul class="c1 lst-kix_list_1-1 start" start="1">
              <li class="c0 c7 li-bullet-0">
                <span class="c2 c10"
                  >Avoid direct contact with the drugs, for example by using
                  gloves or tweezers, along with frequent hand washing.</span
                >
              </li>
              <li class="c0 c7 li-bullet-0">
                <span class="c2">Bottles flipped upside down once used.</span>
              </li>
            </ul>
            <ol class="c1 lst-kix_list_1-0" start="9">
              <li class="c0 c4 li-bullet-0">
                <span class="c2"
                  >Pharmacist inspects stock bottles, placement of all
                  medications and package.</span
                >
              </li>
              <li class="c0 c4 li-bullet-0">
                <span class="c2">Pharmacist seals Package.</span>
              </li>
              <li class="c0 c4 li-bullet-0">
                <span class="c2"
                  >Pharmacist labels compliance package with grid label:</span
                >
              </li>
            </ol>
            <ul class="c1 lst-kix_list_1-1 start" start="1">
              <li class="c0 c7 li-bullet-0">
                <span class="c3"
                  >All container identification markings required by legislation
                  apply to compliance packages.</span
                >
              </li>
              <li class="c0 c7 li-bullet-0">
                <span class="c3"
                  >Labeling includes the information that would appear if each
                  drug had been dispensed in individual vials, in addition to a
                  description of the shape, and colour of the tablet or capsule
                  in a manner that meets the needs of the patient.</span
                >
              </li>
              <li class="c0 c7 li-bullet-0">
                <span class="c3"
                  >The label indicates the dosing specifications for each
                  medication, including day and time, with an emphasis
                  (highlighted on grid label) on those medications that are
                  taken at different frequencies.</span
                >
              </li>
              <li class="c0 c7 li-bullet-0">
                <span class="c3"
                  >Each package is sequentially numbered where
                  appropriate.</span
                >
              </li>
            </ul>
            <ol class="c1 lst-kix_list_1-0" start="12">
              <li class="c0 c4 li-bullet-0">
                <span class="c2"
                  >Pharmacist signs hard copies of all prescriptions</span
                >
              </li>
              <li class="c0 c4 li-bullet-0">
                <span class="c2"
                  >Pharmacist packages compliance packages for pickup or
                  delivery in appropriate paper bags.</span
                >
              </li>
            </ol>
            <ul class="c1 lst-kix_list_1-1 start" start="1">
              <li class="c0 c7 li-bullet-0">
                <span class="c2"
                  >Packages are either immediately packaged for pickup by the
                  patient or</span
                ><span class="c2 c10">,</span>
              </li>
              <li class="c0 c7 li-bullet-0">
                <span class="c2"
                  >Packages are stored under the appropriate conditions until
                  dispensed. (Dry, temperature-controlled and light-controlled
                  storage)</span
                >
              </li>
            </ul>
            <ol class="c1 lst-kix_list_1-0" start="14">
              <li class="c0 c4 li-bullet-0">
                <span class="c2"
                  >Pharmacist signs printed grid and scans to patient profile
                  and filled prescription records</span
                >
              </li>
              <li class="c0 c4 li-bullet-0">
                <span class="c2">Medications returned to shelves.</span>
              </li>
            </ol>
            <p class="c0"><span class="c13">&nbsp;</span></p>
            <p class="c0"><span class="c13">&nbsp;</span></p>
            <p class="c0 c6"><span class="c13"></span></p>
            <p class="c0 c6"><span class="c13"></span></p>
            <h3 class="title">New Patient Checklist:</h3>
            <ul class="c1 lst-kix_list_1-1 start" start="1">
              <li class="c0 c7 li-bullet-0">
                <span class="c2"
                  >Double check grid against original prescription order by at
                  least 2 staff members including a pharmacist</span
                ><span class="c2 c10">,</span>
              </li>
              <li class="c0 c7 li-bullet-0">
                <span class="c2"
                  >Obtain all necessary signatures including FREQUENT DISPENSING
                  AUTHORIZATION.</span
                >
              </li>
              <li class="c0 c7 li-bullet-0">
                <span class="c2"
                  >Have a discussion with the patient/agent about the potential
                  benefits and to explain some of the safety concerns. This
                  discussion may be initiated at the time of a medication review
                  (e.g. Meds-Check). Two items which should be discussed
                  are:</span
                >
              </li>
              <li class="c0 c7 li-bullet-0">
                <span class="c2"
                  >Some medications may require dosage adjustment as the dose
                  originally prescribed was based on inconsistent ingestion.
                  Discuss potential signs and symptoms that indicate that a dose
                  adjustment may be needed, when/how to notify the pharmacist of
                  any problems and how any dose modifications or new medications
                  will be managed.</span
                >
              </li>
              <li class="c0 c7 li-bullet-0">
                <span class="c2"
                  >As compliance packs are not considered &ldquo;child
                  safe&rdquo;, it is advisable to store them in a secure
                  location (to children, some packs may look like candy).</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div class="container">
      <embed
        src="compliance.pdf"
        type="application/pdf"
        width="100%"
        height="800px"
      />
    </div>
    <!-- <section>
      <vue-glide
        :startAt="0"
        :perView="4"
        :perTouch="1"
        :focutAt="2"
        type="carousel"
      >
        <vue-glide-slide>
          <img src="img/theme/victor-garcia.jpg" height="500" width="450" />
        </vue-glide-slide>
        <vue-glide-slide>
          <img src="img/theme/sacha-styles.jpg" height="500" width="450" />
        </vue-glide-slide>
        <vue-glide-slide>
          <img src="img/theme/doyoun-seo.jpg" height="500" width="450" />
        </vue-glide-slide>
        <vue-glide-slide>
          <img src="img/theme/ayo-ogunseinde.jpg" height="500" width="450" />
        </vue-glide-slide>
        <vue-glide-slide>
          <img src="img/theme/sofia-kuniakina.jpg" height="500" width="450" />
        </vue-glide-slide>
        <template slot="control">
          <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
            <i class="ni ni-bold-left"></i>
          </button>
          <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
            <i class="ni ni-bold-right"></i>
          </button>
        </template>
      </vue-glide>
    </section>
    <section class="section mt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto">
            <h3 class="title">Rest of the Story:</h3>
            <p>
              We are here to make life better. And now I look and look around
              and there’s so many Kanyes I've been trying to figure out the bed
              design for the master bedroom at our Hidden Hills compound... and
              thank you for turning my personal jean jacket into a couture
              piece. <br />
              I speak yell scream directly at the old guard on behalf of the
              future. daytime All respect prayers and love to Phife’s family
              Thank you for so much inspiration.
            </p>
            <br />
            <p>
              Thank you Anna for the invite thank you to the whole Vogue team
              And I love you like Kanye loves Kanye Pand Pand Panda I've been
              trying to figure out the bed design for the master bedroom at our
              Hidden Hills compound...The Pablo pop up was almost a pop up of
              influence. All respect prayers and love to Phife’s family Thank
              you for so much inspiration daytime I love this new Ferg album!
              The Life of Pablo is now available for purchase I have a dream.
              Thank you to everybody who made The Life of Pablo the number 1
              album in the world! I'm so proud of the nr #1 song in the country.
              Panda! Good music 2016!
            </p>
            <br />
            <p>
              I love this new Ferg album! The Life of Pablo is now available for
              purchase I have a dream. Thank you to everybody who made The Life
              of Pablo the number 1 album in the world! I'm so proud of the nr
              #1 song in the country. Panda! Good music 2016!
            </p>
          </div>
        </div>
      </div>
    </section> -->
    <!-- <section class="section section-blog-info">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto">
            <div class="card">
              <div class="card-header">
                <h5 class="h3 mb-0">Activity feed</h5>
              </div>
              <div class="card-header d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <a href="javascript:;">
                    <img src="img/faces/team-1.jpg" class="avatar" />
                  </a>
                  <div class="mx-3">
                    <a
                      href="javascript:;"
                      class="text-dark font-weight-600 text-sm"
                      >John Snow</a
                    >
                    <small class="d-block text-muted">3 days ago</small>
                  </div>
                </div>
                <div class="text-right ml-auto">
                  <base-button type="primary" size="sm">
                    <template slot="icon">
                      <span class="btn-inner--icon icon-big"
                        ><i class="ni ni-fat-add"></i
                      ></span>
                    </template>
                    Follow
                  </base-button>
                </div>
              </div>
              <div class="card-body">
                <p class="mb-4">
                  Personal profiles are the perfect way for you to grab their
                  attention and persuade recruiters to continue reading your CV
                  because you’re telling them from the off exactly why they
                  should hire you.
                </p>
                <img
                  alt="Image placeholder"
                  src="img/sections/mohamed.jpg"
                  class="img-fluid rounded"
                />
                <div class="row align-items-center my-3 pb-3 border-bottom">
                  <div class="col-sm-6">
                    <div class="icon-actions">
                      <a href="javascript:;" class="like active">
                        <i class="ni ni-like-2"></i>
                        <span class="text-muted">150</span>
                      </a>
                      <a href="javascript:;">
                        <i class="ni ni-chat-round"></i>
                        <span class="text-muted">36</span>
                      </a>
                      <a href="javascript:;">
                        <i class="ni ni-curved-next"></i>
                        <span class="text-muted">12</span>
                      </a>
                    </div>
                  </div>
                  <div class="col-sm-6 d-none d-sm-block">
                    <div
                      class="d-flex align-items-center justify-content-sm-end"
                    >
                      <div class="avatar-group">
                        <a
                          href="javascript:;"
                          class="avatar avatar-xs rounded-circle"
                          data-toggle="tooltip"
                          data-original-title="Jessica Rowland"
                        >
                          <img
                            alt="Image placeholder"
                            src="img/faces/team-1.jpg"
                            class=""
                          />
                        </a>
                        <a
                          href="javascript:;"
                          class="avatar avatar-xs rounded-circle"
                          data-toggle="tooltip"
                          data-original-title="Audrey Love"
                        >
                          <img
                            alt="Image placeholder"
                            src="img/faces/team-2.jpg"
                            class="rounded-circle"
                          />
                        </a>
                        <a
                          href="javascript:;"
                          class="avatar avatar-xs rounded-circle"
                          data-toggle="tooltip"
                          data-original-title="Michael Lewis"
                        >
                          <img
                            alt="Image placeholder"
                            src="img/faces/team-3.jpg"
                            class="rounded-circle"
                          />
                        </a>
                      </div>
                      <small class="pl-2 font-weight-bold">and 30+ more</small>
                    </div>
                  </div>
                </div>
                <div class="mb-1">
                  <div class="media media-comment">
                    <img
                      alt="Image placeholder"
                      class="media-comment-avatar rounded-circle"
                      src="img/faces/team-1.jpg"
                    />
                    <div class="media-body">
                      <div class="media-comment-text">
                        <h6 class="h5 mt-0">Michael Lewis</h6>
                        <p class="text-sm lh-160">
                          You have the opportunity to play this game of life you
                          need to appreciate every moment. A lot of people don’t
                          appreciate the moment until it’s passed.
                        </p>
                        <div class="icon-actions">
                          <a href="javascript:;" class="like active">
                            <i class="ni ni-like-2"></i>
                            <span class="text-muted">3 likes</span>
                          </a>
                          <a href="javascript:;">
                            <i class="ni ni-curved-next"></i>
                            <span class="text-muted">2 shares</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="media media-comment">
                    <img
                      alt="Image placeholder"
                      class="media-comment-avatar rounded-circle"
                      src="img/faces/team-2.jpg"
                    />
                    <div class="media-body">
                      <div class="media-comment-text">
                        <h6 class="h5 mt-0">Jessica Stones</h6>
                        <p class="text-sm lh-160">
                          I always felt like I could do anything. That’s the
                          main thing people are controlled by! Thoughts- their
                          perception of themselves! They're slowed down.
                        </p>
                        <div class="icon-actions">
                          <a href="javascript:;" class="like active">
                            <i class="ni ni-like-2"></i>
                            <span class="text-muted">10 likes</span>
                          </a>
                          <a href="javascript:;">
                            <i class="ni ni-curved-next"></i>
                            <span class="text-muted">1 share</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="media align-items-center mt-5">
                    <img
                      alt="Image placeholder"
                      class="avatar avatar-lg rounded-circle mb-4"
                      src="img/faces/team-3.jpg"
                    />
                    <div class="media-body">
                      <form>
                        <textarea
                          class="form-control"
                          placeholder="Write your comment"
                          rows="1"
                        ></textarea>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- Blog Section -->
    <Blog></Blog>
  </div>
</template>
<script>
import { Glide, GlideSlide } from "vue-glide-js";
import "vue-glide-js/dist/vue-glide.css";
import Blog from "./sections/Blogs/Blog3.vue";

export default {
  bodyClass: "blog-post",
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
    Blog
  }
};
</script>
<style>
li > ol > li {
  list-style: lower-roman;
}
</style>
