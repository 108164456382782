import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Argon from "./plugins/argon-kit";
import http from "../src/api/http";
import Meta from "vue-meta";

Vue.use(Meta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

Vue.$http = Vue.prototype.$http = http;

Vue.config.productionTip = true;
Vue.use(Argon);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
