<template>
  <div class="wrapper">
    <div class="page-header header-filter">
      <div
        class="page-header-image"
        style="background-image: url('https://pharmaguide.ca/wp-content/uploads/2021/03/woman-filling-in-consent-form-for-coronavirus-vacc-EP97NMU-scaled.jpg');"
      ></div>
      <div class="container">
        <div class="row"></div>
        <div class="row">
          <div class="floating-box bg-default">
            <div class="box text-center">
              <div
                class="icon icon-shape bg-primary icon-xl rounded-circle text-white"
              >
                <i class="ni ni-bullet-list-67"></i>
              </div>
            </div>
            <blockquote class="blockquote text-center mt-4">
              <!-- <p class="mb-0 text-white">Refrigerator Procedure</p> -->
              <h1 class="display-3 text-white">
                Covid-19 Vaccine Consent Form
              </h1>
              <!-- <footer class="blockquote-footer text-white">
                Someone famous in
                <cite title="Source Title">Source Title</cite>
              </footer> -->
            </blockquote>
            <h2 class="lead text-white p-4">
              Please use the form below to provide your consent to receive the
              Covid-19 Vaccine. Please provide accurate information as
              appointments may be cancelled if insufficient information
              provided.
            </h2>
          </div>
        </div>
      </div>
    </div>
    <iframe
      id="JotFormIFrame-210687269640058"
      title="COVID-19 Vaccine Screening and Consent Form"
      onload="window.parent.scrollTo(0,0)"
      allowtransparency="true"
      allowfullscreen="true"
      allow="geolocation; microphone; camera"
      src="https://form.jotform.com/210687269640058"
      frameborder="0"
      style="
      min-width: 100%;
      height:320vh;
      border:none;"
      scrolling="yes"
    >
    </iframe>
  </div>
</template>

<script>
import { Glide, GlideSlide } from "vue-glide-js";
import "vue-glide-js/dist/vue-glide.css";

export default {
  bodyClass: "blog-post",
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  }
};
</script>

<style>
li > ol > li {
  list-style: lower-roman;
}
</style>
