<template>
  <div>
    <div class="mt-2">
      <StoreFinderSearch @search="currentCoordinates = $event" />
    </div>
    <div>
      <StoreFinderList :stores="storesOrderedByDistance" />
    </div>
    <div>
      <StoreFinderMap
        :stores="storesOrderedByDistance"
        :current-location="currentCoordinates"
      />
    </div>
  </div>
</template>

<script>
import { convertDistance, getDistance, orderByDistance } from "geolib";

import StoreFinderList from "./StoreFinderList.vue";
import StoreFinderMap from "./StoreFinderMap.vue";
import StoreFinderSearch from "./StoreFinderSearch.vue";

export default {
  name: `StoreFinder`,
  components: {
    StoreFinderList,
    StoreFinderMap,
    StoreFinderSearch
  },
  props: {
    stores: {
      default: () => [],
      required: true,
      type: Array
    }
  },
  data() {
    return {
      currentCoordinates: null
    };
  },
  computed: {
    storeCoordinates() {
      return this.stores.map(store => ({
        latitude: store.content.address.latitude,
        longitude: store.content.address.longitude
      }));
    },
    storesOrderedByDistance() {
      if (!this.currentCoordinates) return this.stores;

      const orderAndDistance = orderByDistance(
        this.currentCoordinates,
        this.storeCoordinates
      );

      return orderAndDistance.map(storeCoordinate => {
        const distance = getDistance(
          this.currentCoordinates,
          storeCoordinate,
          1
        );
        const store = this.stores.find(
          store =>
            store.content.address.latitude == storeCoordinate.latitude &&
            store.content.address.longitude == storeCoordinate.longitude
        );
        return {
          ...store,
          ...storeCoordinate,
          distance: convertDistance(distance, `km`)
        };
      });
    }
  }
};
</script>
