<template>
  <ul class="list-group">
    <li class="list-group-item" v-for="(store, index) in stores" :key="index">
      <StoreFinderItem
        :index="index"
        :address="store.content.address"
        :distance="store.distance"
        :name="store.name"
        :id="store.content.locationID"
      />
    </li>
  </ul>
</template>

<script>
import StoreFinderItem from "./StoreFinderItem.vue";

export default {
  name: `StoreFinderList`,
  components: {
    StoreFinderItem
  },
  props: {
    stores: {
      default: () => [],
      required: true,
      type: Array
    }
  }
};
</script>
<style lang="scss" scoped>
ul,
li {
  list-style-type: none;
}
ul {
  margin-top: 35px;
}
ul > li:hover {
  background-color: lighten(#5e72e4, 32%);
  border-color: lighten(#5e72e4, 32%);
}
</style>
