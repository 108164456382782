<template>
  <div class="wrapper">
    <div class="page-header header-filter">
      <div
        class="page-header-image"
        style="background-image: url('https://pharmaguide.ca/wp-content/uploads/2021/03/cheerful-pharmacist-holding-digital-tablet-and-smi-98L5GF6-scaled.jpg');"
      ></div>
      <div class="container">
        <div class="row">
          <div
            class="floating-box bg-default d-inline-block"
            style="width: 100%;"
          >
            <blockquote class="text-center mt-4">
              <!-- <p class="mb-0 text-white">Refrigerator Procedure</p> -->
              <h1 class="display-4 text-white text-uppercase">
                About us
              </h1>
              <!-- <footer class="blockquote-footer text-white">
                Someone famous in
                <cite title="Source Title">Source Title</cite>
              </footer> -->
            </blockquote>
            <h2 class="lead text-center text-white p-4 container-center">
             
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="container-center">
      <h2>Help get Canadians vaccinated!</h2>

      <p>
        VaxRx.ca was created by the team at PharmaGuide Inc. to help Canadians
        access COVID-19 vaccines faster!
      </p>
      <p>
        All licensed health care institutions providing vaccinations are welcome
        to join VaxRx.ca
      </p>
      <p>
        <u>This is a non-profit initiative</u> - all funds collected are used to
        cover the costs for vaccination sites.
      </p>

      <card class="bg-gradient-default">
        <template slot="body">
          <h3 class="card-title text-white">Join us!</h3>
          <p class="text-white">
            Already have a scheduling platform but would like to help patients
            find you,
          </p>
          <p class="text-white">
            please reach to us at
            <a
              href="email:&#105;&#110;&#102;&#111;&#64;&#118;&#97;&#120;&#114;&#120;&#46;&#99;&#97;"
              >&#105;&#110;&#102;&#111;&#64;&#118;&#97;&#120;&#114;&#120;&#46;&#99;&#97;</a
            >
          </p>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
// import Team from "./sections/Teams/Team1.vue";

export default {
  components: {
    // Team
  },
  data() {
    return {
      isActive: false
    };
  }
};
</script>
<style lang="scss">
.content {
  margin-top: 4rem;
}
.card-deck {
  margin-top: 4rem;

  .card-image {
    display: flex;
    width: 100%;
    height: 160px;
  }
  .card-img-top {
    object-fit: contain;
    width: 100%;
    padding: 1rem 0;
    height: 160px;
    display: block;
    align-items: center;
    justify-content: center;
  }
}
.container-center {
  max-width: 800px;
  margin: 2rem auto;

  h2 {
    font-size: 1.8rem;
    margin: 2rem 1rem;
  }

  > p {
    font-size: 1.2rem;
    margin: 2rem 1rem;
  }
}
</style>
