<template>
  <header class="header-global">
    <base-nav
      class="navbar-main headroom"
      id="navbar-main"
      :type="navbarType ? navbarType : ''"
      effect="dark"
      expand
    >
      <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
        <img
          :src="require('@/assets/img/vaxrx-logo.png')"
          alt="logo"
          style="margin-top: -10px"
        />
        <h1
          class="text-white d-inline-block"
          style="font-size: 1.6rem; text-transform: none"
        >
          <span style="font-family: 'Heebo', sans-serif; font-weight: 300;"
            >Vax</span
          ><span style="font-family: 'Heebo', sans-serif; font-weight: 500;"
            >Rx</span
          >
        </h1>
      </router-link>

      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand">
          <router-link to="/">
            <img
              :src="require('@/assets/img/vaxrx-logo.png')"
              alt="logo"
              style="margin-top: -10px"
            />
            <h1
              class="text-black d-inline-block"
              style="font-size: 1.6rem; text-transform: none;"
            >
              <span style="font-family: 'Heebo', sans-serif; font-weight: 300;"
                >Vax</span
              ><span style="font-family: 'Heebo', sans-serif; font-weight: 500;"
                >Rx</span
              >
            </h1>
          </router-link>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">
        <!-- <base-dropdown class="nav-item">
                <a role="button" slot="title" href="#" class="nav-link" data-toggle="dropdown">
                    <i class="ni ni-app d-lg-none"></i>
                    <i class="ni ni-collection text-black"></i>
                    <span class="nav-link-inner--text">Pharmacy Registration</span>
                </a>

                <router-link to="" class="dropdown-item">
                    <i class="ni ni-atom text-danger"></i>
                    Login
                </router-link>
            </base-dropdown> -->

        <!-- <base-dropdown class="nav-item">
          <a
            role="button"
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
          >
            <i class="ni ni-single-copy-04 d-lg-none"></i>
            <span class="nav-link-inner--text">Information</span>
          </a>
          <router-link to="" class="dropdown-item">
            <i class="ni ni-satisfied text-warning"></i>
            Vaccine Monographs
          </router-link>
          <router-link to="" class="dropdown-item">
            <i class="ni ni-calendar-grid-58 text-info"></i>
            Vaccine News
          </router-link>
          <router-link to="/consent" class="dropdown-item">
            <i class="ni ni-books text-warning"></i>
            Vaccine Consent Form
          </router-link>
        </base-dropdown> -->
        <!-- <base-dropdown class="nav-item">
                <a role="button" slot="title" href="#" class="nav-link" data-toggle="dropdown">
                    <i class="ni ni-single-copy-04 d-lg-none"></i>
                    <span class="nav-link-inner--text">COVID-19</span>
                </a>
                <router-link to="" class="dropdown-item">
                    <i class="ni ni-delivery-fast text-warning"></i>
                    Regulations
                </router-link>
                <router-link to="/workplacesafety" class="dropdown-item">
                    <i class="ni ni-folder-17 text-info"></i>
                    Workplace Safety Plan
                </router-link>
                <router-link to="" class="dropdown-item">
                    <i class="ni ni-building text-yellow"></i>
                    Guidelines by Ontario Government
                </router-link>
                <router-link to="" class="dropdown-item">
                    <i class="ni ni-collection text-danger"></i>
                    Covid Screening tool
                </router-link>
            </base-dropdown> -->
        <!-- <base-dropdown class="nav-item">
                <a role="button" slot="title" href="#" class="nav-link" data-toggle="dropdown">
                    <i class="ni ni-tablet-button d-lg-none"></i>
                    <span class="nav-link-inner--text">Services</span>
                </a>
 

            </base-dropdown> -->

        <!-- <base-dropdown class="nav-item">
          <a
            role="button"
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
          >
            <i class="ni ni-tablet-button d-lg-none"></i>
            <span class="nav-link-inner--text">Account</span>
          </a>
          <router-link to="/account" class="dropdown-item">
            <i class="ni ni-lock-circle-open text-muted"></i>
            Account Settings
          </router-link>
          <router-link to="/login" class="dropdown-item">
            <i class="ni ni-tv-2 text-danger"></i>
            Login Page
          </router-link>
          <router-link to="/register" class="dropdown-item">
            <i class="ni ni-air-baloon text-pink"></i>
            Register Page
          </router-link>
          <router-link to="/reset" class="dropdown-item">
            <i class="ni ni-atom text-info"></i>
            Reset Page
          </router-link>

          <router-link to="/chat-page" class="dropdown-item">
            <i class="ni ni-chat-round text-primary"></i>
            Chat Page
          </router-link>
        </base-dropdown> -->
        <!-- <router-link to="/signup" class="col-lg-3 col-sm-12 mt-2 nav-link">
                
            </router-link> -->
        <li>
          <router-link class="nav-item" to="/no-available-slots"
            >No appointments?</router-link
          >
        </li>
        <li>
          <router-link class="nav-item" to="/sponsors">Sponsors</router-link>
        </li>
        <li>
          <router-link class="nav-item" to="/about">About us</router-link>
        </li>
        <li>
          <router-link to="/signup" class="text-white">
            <base-button
              type="success"
              class="btn-block"
              style="max-width: 200px; margin-top: 1rem"
              >Pharmacy<br />Registration</base-button
            >
          </router-link>
        </li>
      </ul>
    </base-nav>
  </header>
</template>

<script>
import BaseNav from "@/components/BaseNav";
import CloseButton from "@/components/CloseButton";
// import BaseDropdown from "@/components/BaseDropdown";
import Headroom from "headroom.js";

export default {
  components: {
    BaseNav,
    CloseButton
    // BaseDropdown
  },
  props: {
    navbarType: String
  },
  mounted: function() {
    let headroom = new Headroom(document.getElementById("navbar-main"), {
      offset: 60,
      tolerance: {
        up: 30,
        down: 30
      }
    });
    headroom.init();
  }
};
</script>

<style lang="scss" scoped>
.navbar-main.headroom {
  z-index: 9999;
}

.page-header-small .container {
  margin-top: 135px;
}

a.navbar-brand {
  padding: 2px;
  width: 120px;
  border: 0px none;
  border-bottom: 3px solid transparent;

  &:hover {
    border-bottom-color: white;
  }
}

.navbar-brand {
  img {
    height: 40px;
  }
}

.navbar {
  a {
    color: white;
    margin-right: 2rem;
    border: 0px none;
    border-bottom: 3px solid transparent;
    &:hover {
      border-bottom-color: transparent;
    }
  }

  .navbar-collapse.show a {
    color: var(--blue);
    margin: 0.5rem 0;
    display: block;
  }
}
</style>
