<template>
  <form class="StoreFinderSearch mb-4 mt-3" @submit.prevent="searchAddress">
    <div class="row">
      <div class="col-lg-8 col-sm-12">
        <base-input
          v-model="address"
          placeholder="Enter your address"
          aria-label="Your address"
        ></base-input>
      </div>
      <div class="col-lg-2 col-sm-12 mt-2">
        <base-button
          class="btn-block"
          type="success"
          icon="ni ni-square-pin"
          @click="searchNearBy"
          >Near me</base-button
        >
      </div>
      <div class="col-lg-2 col-sm-12 mt-2">
        <base-button
          class="btn-block"
          type="primary"
          icon="ni ni-zoom-split-in"
          @click="searchAddress"
          >Search</base-button
        >
      </div>
    </div>
  </form>
</template>

<script>
import gmapsInit from "../utils/gmaps";

export default {
  name: `StoreFinderSearch`,
  data() {
    return {
      address: null
    };
  },
  async created() {
    this.google = await gmapsInit();
    this.geocoder = new this.google.maps.Geocoder();
  },
  methods: {
    searchAddress() {
      this.geocoder.geocode(
        {
          address: this.address
        },
        (results, status) => {
          if (status !== `OK` || !results[0]) return;

          // Set address field to the address
          // found by the Google Maps API and
          // emit a search event with the found
          // coordinates.
          this.address = results[0].formatted_address;
          this.$emit(`search`, {
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng()
          });
        }
      );

      this.scrollIntoList();
    },
    async searchNearBy() {
      const { latitude, longitude } = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => resolve(coords),
          // Reject if the user doesn't
          // allow accessing their location.
          error => reject(error)
        );
      });

      const latLng = new this.google.maps.LatLng(latitude, longitude);
      this.geocoder.geocode(
        {
          latLng
        },
        (results, status) => {
          if (status !== `OK` || !results[0]) return;

          // Set address field to the address
          // found by the Google Maps API and
          // emit a search event with the users
          // coordinates.
          this.address = results[0].formatted_address;
          this.$emit(`search`, {
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng()
          });
        }
      );

      this.scrollIntoList();
    },
    scrollIntoList() {
      this.$parent.$children[1].$el.scrollIntoView({
        behavior: "smooth"
      });
    }
  }
};
</script>

<style lang="scss">
.StoreFinderSearch {
  // background-color: black;
  // opacity: .8;
  // padding: 10px 10px 0px;
  .form-control {
    font-size: 1.4rem;
  }
}

// @media (max-width: 992px){
//   .StoreFinderSearch{
//     background-color: transparent;
//     opacity: 1;
//   }
// }
</style>
