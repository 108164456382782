import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";
import Presentation from "./views/Presentation.vue";
// import Components from "./views/Components.vue";
import Home from "./views/Home.vue";
import Sections from "./views/Sections.vue";
import About from "./views/About.vue";
import Sponsors from "./views/Sponsors.vue";
import NoAvailableSlots from "./views/NoAvailableSlots.vue";
import BlogPosts from "./views/BlogPosts.vue";
import BlogPost from "./views/BlogPost.vue";
import Compliance from "./views/Compliance.vue";
import Signup from "./views/Signup.vue";
import Book from "./views/Book.vue";
import Consent from "./views/Consent.vue";
import Error from "./views/Error.vue";
import Error500 from "./views/Error500.vue";
import Register from "./views/Register.vue";
import { authGuard } from "../src/auth";

Vue.use(Router);

export default new Router({
  linkExactActiveClass: "active",
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      // beforeEnter: authGuard,
      components: {
        header: AppHeader,
        default: Home,
        footer: AppFooter
      }
    },
    {
      path: "/presentation",
      name: "presentation",
      beforeEnter: authGuard,
      components: {
        header: AppHeader,
        default: Presentation,
        footer: AppFooter
      },
      props: {
        header: { navbarType: "primary" }
      }
    },
    {
      path: "/sections",
      name: "sections",
      components: {
        header: AppHeader,
        default: Sections,
        footer: AppFooter
      },
      props: {
        // header: { navbarType: "default" }
      }
    },
    {
      path: "/about",
      name: "about",
      components: {
        header: AppHeader,
        default: About,
        footer: AppFooter
      }
    },
    {
      path: "/sponsors",
      name: "sponsors",
      components: {
        header: AppHeader,
        default: Sponsors,
        footer: AppFooter
      }
    },
    {
      path: "/no-available-slots",
      name: "no-available-slots",
      components: {
        header: AppHeader,
        default: NoAvailableSlots,
        footer: AppFooter
      }
    },
    {
      path: "/blog-posts",
      name: "blog-posts",
      components: {
        header: AppHeader,
        default: BlogPosts,
        footer: AppFooter
      }
    },
    {
      path: "/fridge",
      name: "fridge",
      components: {
        header: AppHeader,
        default: BlogPost,
        footer: AppFooter
      }
    },
    {
      path: "/compliance",
      name: "compliance",
      components: {
        header: AppHeader,
        default: Compliance,
        footer: AppFooter
      }
    },
    {
      path: "/signup",
      name: "signup",
      components: {
        header: AppHeader,
        default: Signup,
        footer: AppFooter
      }
    },
    {
      path: "/consent",
      name: "consent",
      components: {
        header: AppHeader,
        default: Consent,
        footer: AppFooter
      }
    },
    {
      path: "/book/:name",
      name: "book",
      components: {
        header: AppHeader,
        default: Book,
        footer: AppFooter
      }
    },
    {
      path: "/error",
      name: "error",
      components: {
        header: AppHeader,
        default: Error
      },
      props: {
        header: { navbarType: "default" }
      }
    },
    {
      path: "/*",
      name: "error",
      components: {
        header: AppHeader,
        default: Error
      },
      props: {
        header: { navbarType: "default" }
      }
    },
    {
      path: "/500-error",
      name: "500-error",
      components: {
        header: AppHeader,
        default: Error500
      },
      props: {
        header: { navbarType: "primary" }
      }
    },
    {
      path: "/register",
      name: "register",
      components: {
        header: AppHeader,
        default: Register,
        footer: AppFooter
      }
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth"
      };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});
