<template>
  <div id="app">
    <router-view name="header"></router-view>
    <main>
      <!-- <fade-transition origin="center" mode="out-in" :duration="250"> -->
      <router-view />
      <!-- </fade-transition> -->
    </main>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
// import { FadeTransition } from "vue2-transitions";

export default {
  metaInfo: {
    title: "VaxRx By Pharmaguide Inc.",
    meta: [
      {
        name: "description",
        content:
          "VaxRx.ca - COVID-19 vaccine avaliable now in Ontario, book your appointment with a nearby pharmacy in seconds"
      }
    ]
  },
  components: {
    // FadeTransition
  },
  data() {
    return {
      // userId: this.$auth.user.sub,
      // name: this.$auth.user.name,
      // email: this.$auth.user.email,
    };
  },
  created() {
    return {
      // userId: this.$auth.user.sub,
      // name: this.$auth.user.name,
      // email: this.$auth.user.email,
    };
  }
  //   updated() {
  //   this.$intercom.boot({
  //     userId: this.$auth.user.sub,
  //     name: this.$auth.user.name,
  //     email: this.$auth.user.email,
  //   });
  //   if (this.$auth.isAuthenticated) {
  //     this.$intercom.update({
  //       user_id: this.$auth.user.email,
  //       name: this.$auth.user.name,
  //       email: this.$auth.user.email
  //     });
  //   } else {
  //     this.$intercom.shutdown();
  //   }
  // }
};
</script>
n
