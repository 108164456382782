<template>
  <div class="wrapper">
    <div class="page-header header-filter">
      <div
        class="page-header-image"
        style="background-image: url('https://pharmaguide.ca/wp-content/uploads/2021/03/cheerful-pharmacist-holding-digital-tablet-and-smi-98L5GF6-scaled.jpg');"
      ></div>
      <div class="container">
        <div class="row">
          <div
            class="floating-box bg-default d-inline-block"
            style="width: 100%;"
          >
            <blockquote class="text-center mt-4">
              <!-- <p class="mb-0 text-white">Refrigerator Procedure</p> -->
              <h1 class="display-4 text-white text-uppercase">
                No available appointments for booking?
              </h1>
              <!-- <footer class="blockquote-footer text-white">
                Someone famous in
                <cite title="Source Title">Source Title</cite>
              </footer> -->
            </blockquote>
            <h2 class="lead text-center text-white p-4 container-center">
              Help us spread the word about VaxRx.ca Mission! 
              <p>
                Do you already have a scheduling software and would like to be listed as an alternative? Click <a href="https://form.jotform.com/211227627480251" target="https://form.jotform.com/211227627480251">here</a> to get added below!
                </p>
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="container-center">
      <h2>We are all in this together!</h2>

      <p>
        There are other vaccination web-sites that could have bookings avaliable!
      </p>
      <p>
        Any questions? Contact us directly at
        <a
          href="email:&#105;&#110;&#102;&#111;&#64;&#118;&#97;&#120;&#114;&#120;&#46;&#99;&#97;"
          >&#105;&#110;&#102;&#111;&#64;&#118;&#97;&#120;&#114;&#120;&#46;&#99;&#97;</a
        >
      </p>

      <div class="card-deck">
        <card
          class="card-logo"
          v-for="pharmacy in pharmacies"
          :key="pharmacy.name"
        >
          <template slot="image">
            <a :href="pharmacy.url">
              <img
                :style="pharmacy.imageStyle"
                class="card-img-top"
                :src="require(`@/assets/img/logo/${pharmacy.image}`)"
                alt="Image placeholder"
              />
            </a>
          </template>
          <template slot="body">
            <h5 class="card-title">
              <a :href="pharmacy.url">{{ pharmacy.name }}</a>
            </h5>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
// import Team from "./sections/Teams/Team1.vue";

export default {
  components: {
    // Team
  },
  data() {
    return {
      isActive: false,
      pharmacies: [
        {
          name: "Shoppers Drug Mart",
          image: "shoppers-drugmart-logo.png",
          imageStyle: "background-color: #CB333B;",
          url:
            "https://shoppers-drug-mart.c.eforms.loblaw.ca/#/covid/covid-19-vaccine-patient-registration/Welcome/0?lang=en"
        },
        {
          name: "Walmart",
          image: "walmart-logo.png",
          imageStyle: "background-color: white;",
          url:
            "https://www.walmart.ca/cp/shop/covid-19-vaccinations-centre-info/6000203072273"
        },
        {
          name: "Rexall",
          image: "rexall-trileaf-logo.png",
          imageStyle: "background-color: #1db1a8;",
          url: "https://www.rexall.ca/covid-19/vaccines"
        }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
.content {
  margin-top: 4rem;
}
.card-logo {
  height: 250px;
}
.card-deck {
  margin-top: 4rem;

  .card-image {
    display: flex;
    width: 100%;
    height: 160px;
  }
  .card-img-top {
    object-fit: contain;
    width: 100%;
    padding: 1rem;
    height: 160px;
    display: block;
    align-items: center;
    justify-content: center;
  }
}
.container-center {
  max-width: 800px;
  margin: 2rem auto;

  h2 {
    font-size: 1.8rem;
    margin: 2rem 1rem;
  }

  > p {
    font-size: 1.2rem;
    margin: 2rem 1rem;
  }
}
</style>
