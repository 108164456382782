<template>
  <div class="StoreFinderMap" />
</template>

<script>
import MarkerClusterer from "@google/markerclusterer";

import gmapsInit from "../utils/gmaps";

export default {
  name: `StoreFinderMap`,
  props: {
    currentLocation: {
      default: () => ({}),
      type: Object
    },
    stores: {
      default: () => [],
      required: true,
      type: Array
    }
  },
  watch: {
    async currentLocation() {
      // BEGIN ORIGINAL

      // Zoom to the nearest store relative
      // to the current location.
      // const bounds = new this.google.maps.LatLngBounds();
      // const nearestStore = this.stores[0];
      // const { latitude, longitude } = nearestStore.content.address;
      // const latLng = new this.google.maps.LatLng(latitude, longitude);
      // this.geocoder.geocode({ latLng }, (results, status) => {
      //   if (status !== `OK` || !results[0]) return;

      //   // bounds.extend(marker.position);

      //   this.map.setCenter(results[0].geometry.location);
      //   this.map.fitBounds(results[0].geometry.viewport);

      //   // const marker = new this.google.maps.Marker({
      //   //   position: {
      //   //     lat: store.content.address.latitude,
      //   //     lng: store.content.address.longitude,
      //   //   },
      //   //   map: this.map,
      //   // });
      //   // marker.addListener(`click`, () => markerClickHandler(marker));
      // });

      // END ORIGINAL

      const bounds = new this.google.maps.LatLngBounds();
      // // Initialize and cluster markers.
      const markerClickHandler = marker => {
        this.map.setZoom(16);
        this.map.setCenter(marker.getPosition());
      };
      const markers = this.stores.map(store => {
        const marker = new this.google.maps.Marker({
          position: {
            lat: store.content.address.latitude,
            lng: store.content.address.longitude
          },
          map: this.map
        });
        marker.addListener(`click`, () => markerClickHandler(marker));
        bounds.extend(marker.position);
        return marker;
      });

      const { latitude, longitude } = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => resolve(coords),
          // Reject if the user doesn't
          // allow accessing their location.
          error => reject(error)
        );
      });

      const latLng = new this.google.maps.LatLng(latitude, longitude);
      this.geocoder.geocode({ latLng }, (results, status) => {
        if (status !== `OK` || !results[0]) return;

        const marker = new this.google.maps.Marker({
          position: {
            lat: latitude,
            lng: longitude
          },
          map: this.map
        });
        marker.addListener(`click`, () => markerClickHandler(marker));
        bounds.extend(marker.position);
        // add it to the location
        markers.push(marker);
      });

      // eslint-disable-next-line no-new
      new MarkerClusterer(this.map, markers, {
        imagePath: `https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m`
      });

      //now fit the map to the newly inclusive bounds
      this.map.fitBounds(bounds);
    }
  },
  async mounted() {
    try {
      this.google = await gmapsInit();
      this.geocoder = new this.google.maps.Geocoder();
      this.map = new this.google.maps.Map(this.$el, {
        disableDefaultUI: true,
        mapTypeId: this.google.maps.MapTypeId.ROADMAP,
        // panControl: true,
        zoomControl: true,
        // mapTypeControl: true,
        scaleControl: true
        // streetViewControl: true,
        // overviewMapControl: true,
        // rotateControl: true
      });

      // Initialize and cluster markers.
      const markerClickHandler = marker => {
        this.map.setZoom(16);
        this.map.setCenter(marker.getPosition());
      };
      const bounds = new this.google.maps.LatLngBounds();
      const markers = this.stores.map(store => {
        const marker = new this.google.maps.Marker({
          position: {
            lat: store.content.address.latitude,
            lng: store.content.address.longitude
          },
          map: this.map
        });
        marker.addListener(`click`, () => markerClickHandler(marker));
        bounds.extend(marker.position);
        return marker;
      });
      // eslint-disable-next-line no-new
      new MarkerClusterer(this.map, markers, {
        imagePath: `https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m`
      });

      //now fit the map to the newly inclusive bounds
      this.map.fitBounds(bounds);
    } catch (error) {
      // Implement your own error handling here.
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
};
</script>

<style lang="scss">
.StoreFinderMap {
  width: 100%;
  height: 100%;
  min-height: 30em;
}
</style>
