<template>
  <div class="wrapper">
    <div class="page-header page-header-small header-filter">
      <div
        class="page-header-image"
        style="background-image: url('https://pharmaguide.ca/wp-content/uploads/2021/03/flu-vaccine-Q235SLH-scaled.jpg');"
      ></div>

      <div class="container">
        <div class="row">
          <div class="col floating-box bg-default pt-4 pb-4">
            <!-- <div class="box text-center">
              <div
                class="icon icon-shape bg-primary icon-xl rounded-circle text-white"
              >
                <i class="ni ni-collection"></i>
              </div>
            </div> -->
            <blockquote class="text-center">
              <h1
                class="text-white font-weight-bold"
                style="font-size: 2rem; line-height: 30px"
              >
                COVID-19 vaccine avaliable now!
              </h1>
              <h2
                class="text-white font-weight-lighter"
                style="font-size: 1.4rem; line-height: 24px;"
              >
                Book your appointment with a nearby pharmacy in seconds
              </h2>
            </blockquote>
            <div class="text-white mt-3" style="max-width: 800px;margin: auto;">
              <div class="font-weight-bold ml-4">
                BEFORE YOU BOOK, YOU MUST:
              </div>
              <ul class="mt-1" style="font-size: 1.0rem;">
                <li class="mt-1">
                  Be 18 years or older in 2021.
                </li>
                <li class="mt-1">
                  Have a valid Ontario health (OHIP) card, or other form of
                  valid government-issued identification
                </li>
              </ul>
              <div class="ml-4">
                To learn more, please visit
                <a
                  class="text-white font-weight-bold"
                  style="text-decoration: underline"
                  href="https://covid-19.ontario.ca/get-covid-19-vaccine"
                  target="_blank"
                  rel="noopener"
                  >COVID-19 vaccination in Ontario</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main main-raised">
      <div class="container">
        <div class="row">
          <div class="pg-card col-lg-12 col-md-12 mx-auto">
            <!-- <h3 class="card-title">
                  Pharmacy finder
                </h3> -->
            <div class="card-body">
              <StoreFinder v-if="stores.length" :stores="stores" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as storeService from "./services/store";

import StoreFinder from "../views/components/StoreFinder.vue";
// import Blog from "./sections/Blogs/Blog3.vue";
// import Blog2 from "./sections/Blogs/Blog4.vue";
// import BaseDropdown from "@/components/BaseDropdown";

export default {
  metaInfo: {
    titleTemplate: "%s - Book now",
    meta: [
      {
        name: "description",
        content:
          "VaxRx.ca - COVID-19 vaccine avaliable now in Ontario, book your appointment with a nearby pharmacy in seconds"
      }
    ]
  },
  components: {
    // Blog,
    StoreFinder
    // Blog2,
    // BaseDropdown,
  },
  data() {
    return {
      stores: []
    };
  },
  created() {
    // Initially fetch all stores.
    this.fetchStores();
  },

  methods: {
    async fetchStores() {
      this.stores = await storeService.list();
    }
  }
  // methods: {
  //   forward() {
  //     this.$router.push(
  //       'fridge'
  //       );
  //   }
  // }
};
</script>

<style lang="scss" scoped>
.page-header {
  .floating-box {
    margin-top: 4em;
  }
}

.pg-card {
  background-color: black !important;
  opacity: 0.8;
  // padding: 10px 10px;
  box-shadow: 0 0 2px 2px 000;
  border-radius: 4px;

  .card-title {
    color: white;
    font-size: 2.4rem;
    padding: 30px 0 10px;
  }

  .card-body {
    font-size: 1.2rem;
    padding: 0px;
  }
}

@media (max-width: 992px) {
  .pg-card {
    margin-top: 15px;
  }
}

@media (max-width: 576px) {
  .pg-card {
    margin-top: 97px;
  }
}
</style>
