<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-1 col-2 mt-1">
        <badge
          type="default"
          class="font-weight-bolder"
          style="font-size: 1.1rem;"
          >{{ index + 1 }}</badge
        >
      </div>
      <div class="col-lg-8 col-md-5 col-sm-5 col-10">
        <h5 class="text-uppercase">{{ name }}</h5>
        <div class="font-weight-lighter mb-3">
          {{ address.street }}<br />
          {{ address.town }},
          <span class="text-uppercase">{{ address.postal_code }}</span>
        </div>
        <div class="mb-2">
          <i class="ni ni-bold-right text-default mr-2"></i>
          <a
            :href="`tel:${address.phone}`"
            class="text-default"
            style="margin-top: -2px;"
            >{{ address.phone | phone }}</a
          >
        </div>
        <div clas="mt-4">
          <i class="ni ni-bold-right text-default mr-2"></i>
          <a :href="directionsUrl" target="_blank" rel="noopener">Directions</a>
        </div>
      </div>
      <div
        class="col col-lg-3 col-md-5 col-sm-6 col-xs-5 mt-5 text-right btn-left"
      >
        <router-link
          :to="{
            name: 'book',
            params: { id: id, name: name.toLowerCase().replaceAll(' ', '-') }
          }"
          class="text-white"
        >
          <base-button type="primary">
            <i class="ni ni-calendar-grid-58 text-white mr-2"></i
            ><span class="">Book now</span>
            <br />
            <span v-if="closestAvaliableTime.time" class="font-weight-normal"
              >{{ closestAvaliableTime.date }} at
              {{ closestAvaliableTime.time }}</span
            >
          </base-button>
        </router-link>
        <div style="line-height: 10px">
          <i style="font-size: 50%;" v-if="closestAvaliableTime.retrivedAt"
            >Retrived on: {{ closestAvaliableTime.retrivedAt }}</i
          >
        </div>
        <div v-if="distance" class="mt-2 font-weight-lighter">
          <i
            class="ni ni-compass-04 text-default mr-2"
            style="vertical-align: middle;"
          ></i>
          <span v-if="distance">{{ distance.toFixed(2) }} km</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "../../components/BaseButton.vue";
import * as dayjs from "dayjs";
// import OpeningHours from './OpeningHours.vue';

export default {
  name: `StoreFinderItem`,
  components: {
    BaseButton
    // OpeningHours,
  },
  data: () => ({
    closestAvaliableTime: {
      date: "",
      time: "",
      retrivedAt: ""
    }
  }),
  props: {
    index: {
      type: Number
    },
    address: {
      default: () => ({}),
      required: true,
      type: Object
    },
    distance: {
      default: null,
      type: Number
    },
    name: {
      default: ``,
      required: true,
      type: String
    },
    id: {
      default: ``,
      required: true,
      type: String
    },
    availability: {
      type: null
    }
  },
  created() {
    // Create a Google Maps URL,
    // for directions to the shop.
    const url = `https://www.google.com/maps/dir/?api=1`;
    const destination = [
      this.address.street,
      `${this.address.postal_code} ${this.address.town}`
    ].join(`, `);
    this.directionsUrl = `${url}&destination=${encodeURI(destination)}`;
  },
  mounted() {
    this.updateClosestAvaliableTime();
  },
  watch: {
    name() {
      this.closestAvaliableTim = {
        date: "",
        time: "",
        retrivedAt: ""
      };
      this.$nextTick(() => {
        this.updateClosestAvaliableTime(this.id);
      });
    }
  },
  methods: {
    async updateClosestAvaliableTime(id) {
      let closestAvaliableTime = {
        date: "",
        time: "",
        retrivedAt: ""
      };
      const availability = await this.getAvailability(id || this.id);
      if (availability && availability.dates && availability.dates.length > 0) {
        const earliestAvaliableDate = availability.dates[0].date;
        const earliestAvaliableTime = availability.dates[0].times[0].time;
        closestAvaliableTime = {
          date: earliestAvaliableDate,
          time: earliestAvaliableTime,
          retrivedAt: dayjs().format("MMM D, YYYY h:mm A")
        };
      }

      this.closestAvaliableTime = closestAvaliableTime;
    },
    async getAvailability(locationID) {
      const rawResponse = await fetch(
        "https://enw0tbuhhv99j0q.m.pipedream.net",
        {
          method: "POST", // damn pipedream and query params
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Cache-Control": "no-cache"
          },
          body: JSON.stringify({
            locationID
          })
        }
      );

      return rawResponse.json();
    }
  },
  filters: {
    phone: phone => {
      return phone
        .replace(/[^0-9]/g, "")
        .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: 576px) {
  .text-right {
    text-align: left !important;
    .btn {
      width: 100%;
    }
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .btn-left {
    margin-left: 50px;
  }
}
</style>
